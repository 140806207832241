import React, { useState } from 'react';

const MapPoint = ({ x, y, onPointHover, onPointLeave }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div
      className="map-point"
      style={{
        left: `${x}%`,
        top: `${y}%`,
        backgroundColor: isSelected ? '#000538' : '#00ACEE',
      }}
      onMouseEnter={() => {
        setIsSelected(true);
        onPointHover({ x, y });
      }}
      onMouseLeave={() => {
        setIsSelected(false);
        onPointLeave();
      }}
    />
  );
};

export default MapPoint;
