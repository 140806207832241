import React, { Fragment, useState, useContext  } from "react";
import { Link } from "gatsby";
import { LoadingContext } from "../context/LoadingContext";
import i18n from "i18next";

const WorkingTogether = ({}) => {
  const { windowWidth } = useContext(LoadingContext);

  const handleRegisterButtonClick = () => {
    // Redirigir a la URL proporcionada
    window.location.href = 'https://www.candidatesexstaff.com.co/';
  };
    
  return (
    <>
            {windowWidth >= 1025 ?  (

              <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerAcademy">
                <div className="flip-card-inner2 contentPopularLocations mt-24 mb-24">
                    <div > 
                      <p className='titleBannerAcademy contentMediaTeams'>{i18n.t("pagejoinourteam.WorkingTogether.text")}</p> 
                      <button className='mobile:mt-4 contentButtomMoreAcademy'  style={{alignSelf:'center'}} onClick={handleRegisterButtonClick}>
                          <p className='textButtomGetTouch'>{i18n.t("pagejoinourteam.WorkingTogether.bottom")}</p>
                      </button>
                    </div>
                </div>
              </div>

              )  : (
              <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerAcademyMoblie">

                <div className="flip-card-inner2   mt-24 mb-24">
                    <div >
                      <p className='titleBannerAcademy'>{i18n.t("pagejoinourteam.WorkingTogether.text")}</p> 
                      <button className='mobile:mt-4 contentButtomMoreAcademy' style={{alignSelf:'center', width: '90%', margin: 'auto'}} onClick={handleRegisterButtonClick}>
                          <p className='textButtomGetTouch'>{i18n.t("pagejoinourteam.WorkingTogether.bottom")}</p>
                      </button>
                    </div>
                </div>
              </div>
            )}
    </>
  ); 
};

export default WorkingTogether;