import React, { useContext } from 'react';
import i18n from "i18next";
import Slider from "react-slick";
import { LoadingContext } from '../../components/context/LoadingContext';
import image1 from '../../images/career/FrameCompanies.png'
import image2 from '../../images/career/FrameCompanies2.png';

const CompaniesWork = () => {

    const { windowWidth } = useContext(LoadingContext);

     

    return (
        <>
            
            {windowWidth >= 1025 ?
              <> 
              <div className={`p-6 `}>
              {windowWidth >= 1025 && windowWidth <= 1700 ? 

        <div className="flex  ml-16 contentApply mr-12 mb-12">

                    
                    <div className="mt-4">
                    <div className='flex'>
                    <div>
                    <div className="eventRightTimeLineMobile ml-6">
                    <p className='titleOurServices contentWorkCompanies'>{i18n.t("pagejoinourteam.CompaniesWork.title")}</p> 
                    </div>
                
                            <p className="textExclusiveProducts ml-6">
                            {i18n.t("pagejoinourteam.CompaniesWork.text")}
                        </p>   
                        </div> 
                         <div className=" divInfoExsisAcademyBootcamps ">    
                       <div className='ml-4'>
                               <img src={image1} alt="...." className=""/>
                           </div>
                   </div>  
                   </div>
                        
                    </div> 
                    
                    </div>:
                    <div className="flex  ml-16  mr-12 mb-12">

                    
                    <div className="mt-4"> 
                    <div className='flex'>
                        <div>
                    <div className="eventRightTimeLineMobile">
                    <p className='titleOurServices'>{i18n.t("pagejoinourteam.CompaniesWork.title")}</p> 
                    </div>
                       
                            <p className="textExclusiveProducts">
                            {i18n.t("pagejoinourteam.CompaniesWork.text")}
                        </p>   
                        </div>

                         <div className="  divInfoExsisAcademyBootcamps ">    
                       <div className='ml-56'>
                               <img src={image1} alt="...." className=""/>
                           </div>
                   </div>
                   </div>  
                        
                    </div> 
                    </div>
                        
                 }</div>   
                 </> :
                <div className={`p-4 `}>
                    <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20">
                    <div className="ml-1 mr-1">
                    <div className="eventRightTimeLineMobile">
                    <p className='titleOurServices'>{i18n.t("pagejoinourteam.CompaniesWork.title")}</p> 
                    </div>
                
                            <p className="text_our_experienceMobile mt-4">
                            {i18n.t("pagejoinourteam.CompaniesWork.text")}
                        </p>     

                            <div className='mt-4'>
                               <img src={image2} alt="...." className=""/>
                           </div>
                    </div> 
                </div>
            </div>
                    
            }            
    
        </>
    )
}



export default CompaniesWork
