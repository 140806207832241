import React, { useContext, useState } from 'react';
import i18n from "i18next";
import Slider from "react-slick";
import { LoadingContext } from '../../components/context/LoadingContext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrowLeft from "./../../images/home/arrowLeft.jpg"
import arrowRigth from "./../../images/home/arrowRigth.jpg"


const BenefitsAndPerks = ({  arrayitems }) => {

    const { windowWidth } = useContext(LoadingContext);
    const [currentSlide, setCurrentSlide] = useState(0);

    const imgStyle = {
        maxWidth: '1.8rem',
        maxHeight: '3rem'
      };

    const imgStyle2 = {
        maxWidth: '6rem',
        maxHeight: '5.5rem'
    };

    const imgStylemovil = {
      maxWidth: '1.2rem',
      maxHeight: '3rem'
  };

  const settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    autoPlaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    speed: 1000, 
    fade: false,
    dotsClass: 'slick-dots blue-dark-slide-dots--small hidden-desktop__dot',
    transition: 'all 0.2s',
  };

  const settingsMobile = {
    dots: false,
    infinite: true,
    autoPlay: true,
    loop: true,
    autoPlaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    speed: 500, 
    fade: false,
    dotsClass: 'slick-dots blue-dark-slide-dots--small hidden-desktop__dot',
    transition: 'all 0.2s',
  };
    
    const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + arrayitems.length) % arrayitems.length);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % arrayitems.length);
  };
    
  const renderCarouselItems = () => {
    const carouselItems = [];
    for (let i = currentSlide; carouselItems.length < 3; i++) {
      const itemIndex = i >= arrayitems.length ? i % arrayitems.length : i;
      carouselItems.push(arrayitems[itemIndex]);
    }
    return carouselItems;
  };

    return (
        <div className={`desktop:p-24 p-6 `}>
            
            { windowWidth >= 1025 ? <>
                <div className='mt-4 flex'>
                <div>
                    <p className='subTitleOurServices'>{i18n.t("pagejoinourteam.Benefits.title")}</p>
                    <p className='titleOurServices titleBenefits2'>{i18n.t("pagejoinourteam.Benefits.subtitle")}</p> 
                    </div>

                    <div className={windowWidth>=1700 ? 'desktop:flex ml-auto desktop:mr-8 marginArrowsBenefits mobile:mt-8' : 'desktop:flex ml-auto desktop:mr-8 bigdesktop:mr-56 mobile:mt-8'}>
                          <button onClick={handlePrevSlide}>
                          <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-24"/>
                          </button>
                          <button onClick={handleNextSlide}>
                          <img src={arrowRigth} alt="Imagen PopUp" className=""/>
                          </button>
                        </div>
                    
            </div>
            </>: <>
            <div className='mt-4'>
                <div>
                    <p className='subTitleOurServices'>{i18n.t("pagejoinourteam.Benefits.title")}</p>
                    <p className='titleOurServices mb-10'>{i18n.t("pagejoinourteam.Benefits.subtitle")}</p> 
                    </div>

                    <div className='desktop:flex ml-auto desktop:mr-12 mobile:mt-8'>
                          <button onClick={handlePrevSlide}> 
                          <img src={arrowLeft} alt="Imagen PopUp" className="mt-1 mr-8"/>
                          </button>
                          <button onClick={handleNextSlide}>
                          <img src={arrowRigth} alt="Imagen PopUp" className=""/>
                          </button>
                        </div>
                         
            </div>
 
            </>

            }
            
           
          <div className="desktop:flex desktop:flex-wrap contenfImgCoreSolution mobile:mt-8">
            { windowWidth >= 1025 ? (
              <Slider {...settings}>{
                    renderCarouselItems().map((item, i) => {
                        return (
                        <div key={i.toString()} className=" mb-6">
                          <div className='contentDivBenefitsPerks'>
                              <div className="flex justify-center mb-2 mt-4 ">
                                  <img src={item.image} style={imgStyle2}  className="mr-1 mt-1"/>
                              </div>
                            <div className="flex flew-row text-left w-auto "> 

                              <div className="w-full text-left">
                                  <p className="pr-2 pl-2 textDivBenefitsPerks desktop:text-20">{item.textoEnglish}</p>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        );
                    }) 
                  }
                  </Slider>
                    
                )
            : null }

            { windowWidth < 1025 ? (
                            <Slider {...settingsMobile}>
                                {
                                    renderCarouselItems().map((item, i) => {
                                        return (
                                        <div key={i.toString()} className="flex mb-6">
                                            <div className="text-left w-auto contentDivTechnologyStack"> 
                                                <div className="flex justify-center mb-2">
                                                    <img src={item.image} style={imgStyle2}  className="mr-1 mt-1"/>
                                                </div>
                                                <div className="w-full text-left">
                                                    <p className="pr-2 pl-2 textDivBenefitsPerks desktop:text-20 mobile:text-16">{item.textoEnglish}</p>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        );
                                    })
                                }
                            </Slider>
                        )
            : null }
         
          </div>
          <br/>
        </div>
    )
}



export default BenefitsAndPerks
