import React, { useState, useEffect } from "react"
import i18n from "i18next"
import columnImageRight from "../images/ourDNA/BannerCarers.png"
import benefits1 from "../images/career/FlexibleWorkBG.png"
import benefits2 from "../images/agileTeam/MotivatedteamBG.png"
import benefits3 from "../images/agileTeam/ProjectProtectionBG.png"
import benefits4 from "../images/agileTeam/CareerplanBG.png"
import benefits5 from "../images/agileTeam/ComplementaryhealthplanBG.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import teamEs from "../components/ourDNA/serviceOurTeam/teamEs"
import teamEn from "../components/ourDNA/serviceOurTeam/teamEn"
import BannerExceptionalCareers from "../components/exceptionalCareers/BannerExceptionalCareers"
import Vacancies from "../components/exceptionalCareers/Vacancies"
import OurCulture from "../components/exceptionalCareers/OurCulture"
import BenefitsAndPerks from "../components/exceptionalCareers/BenefitsAndPerks"
import CompaniesWork from "../components/exceptionalCareers/CompaniesWork"
import WorkingTogether from "../components/exceptionalCareers/WorkingTogether"
import MapLocation from "../components/exceptionalCareers/MapLocation"

const ExceptionalCareers = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([])
  const [youtubeVideoId, setYoutubeVideoId] = useState("")
  const [team, setTeam] = useState(teamEs)

  useEffect(() => {
    if (i18n.language === "en") {
      setTeam(teamEn)
      setYoutubeVideoId("GcDt4A3Xbn4")
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
          <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
            <span className="textOurExperience2 flex">
              <p className="colorTextExperience2">E</p>
              <p className="colorTextExperience">x</p>
              <p className="colorTextExperience2">ceptional</p>
            </span>
            <span className="textOurExperience">careers</span>
          </h3>
        </div>,
      ])
    } else {
      setTeam(teamEs)
      setYoutubeVideoId("EGpJEq_JRPA")
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
          <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
            <span className="textOurExperience">Carreras</span>
            <span className="textOurExperience2 flex">
              <p className="colorTextExperience2">E</p>
              <p className="colorTextExperience">x</p>
              <p className="colorTextExperience2">cepcionales</p>
            </span>
          </h3>
        </div>,
      ])
    }
  }, [i18n.language])

  const targetBenefits = [
    {
      image: benefits1,
      textoEnglish: i18n.t("pagejoinourteam.vacancies.benefitsandperks.txt1"),
    },
    {
      image: benefits2,
      textoEnglish: i18n.t("pagejoinourteam.vacancies.benefitsandperks.txt2"),
    },
    {
      image: benefits3,
      textoEnglish: i18n.t("pagejoinourteam.vacancies.benefitsandperks.txt3"),
    },
    {
      image: benefits5,
      textoEnglish: i18n.t("pagejoinourteam.vacancies.benefitsandperks.txt5"),
    },
    {
      image: benefits4,
      textoEnglish: i18n.t("pagejoinourteam.vacancies.benefitsandperks.txt4"),
    },
  ]

  return (
    <Layout>
      <SEO
        title={"ExceptionalCareers | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />
      <BannerExceptionalCareers
        bannerImageURL={columnImageRight}
        bannerImageContent={descriptionTitleBanner}
      ></BannerExceptionalCareers>
      <Vacancies />
      <OurCulture />
      <BenefitsAndPerks arrayitems={targetBenefits} />
      <CompaniesWork />
      <WorkingTogether />
      <MapLocation />
    </Layout>
  )
}

export default withNamespaces()(ExceptionalCareers)
