import React, { Fragment, useState, useContext  } from "react";

import { LoadingContext } from "../context/LoadingContext";



const BannerExceptionalCareers = ({ bannerImageURL, bannerImageContent}) => {
    const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0);
    const { windowWidth } = useContext(LoadingContext);

    return (
        <Fragment>
            <div className="container-banner-careers">
                <div className="shared-banner-container">
                {windowWidth >= 1025 ?  (
                            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                                {bannerImageContent}
                            </div>
                            <div  className="shared-banner-imageHome mobile:ml-0">
                                <div className="bannerExperienceContentStyle">
                                <img 
                                    src={bannerImageURL} 
                                    alt="Imagen PopUp" 
                                    className="imgBannerExperience" 
                                    />
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobileCareers">
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                                {bannerImageContent}
                            </div>
                        <div  className="shared-banner-imageHomeMobile mobile:ml-0">
                            <div className="bannerContentStyleMobile">
                            <img 
                                src={bannerImageURL} 
                                alt="Imagen PopUp" 
                                className="hidden tablet:block imgBannerExperience"
                            />
                            </div>
                        </div>
                    </div>
                  )}

                  
                </div>
            </div>
        </Fragment>

    )
}

BannerExceptionalCareers.defaultProps = {
    flag: true,
    clase: 'shared-banner-footer banner-text-white mt-8',
    style: {}
}

export default BannerExceptionalCareers;
