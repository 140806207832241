import React, { useContext, useState, useEffect, useRef } from 'react';
import i18n from "i18next";
import { LoadingContext } from '../context/LoadingContext';
import mapImage from '../../images/career/map.png'
import MapPoint from './MapPoint';
import maira from '../../images/career/testimonials/Maira.png'
import santiago from '../../images/career/testimonials/Santiago-jimenez.png'
import hector from '../../images/career/testimonials/hector-Silva.png'
import daniel from '../../images/career/testimonials/Don-Daniel.png'


const MapLocation = () => {

  const { windowWidth } = useContext(LoadingContext);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [mapWidth, setMapWidth] = useState(null);
  const [mapHeight, setMapHeight] = useState(null);
  const mapImageRef = useRef(null);

  const pointsData = [
    {
      id: 1,
      x: 24.7,
      y: 60.7,
      test: i18n.t("pagejoinourteam.PopularLocation.p1.test"),
      name: i18n.t("pagejoinourteam.PopularLocation.p1.name"),
      locprof: i18n.t("pagejoinourteam.PopularLocation.p1.loc&prof"),
      photo: maira,
    },
    {
      id: 2,
      x: 17,
      y: 52,
      test: i18n.t("pagejoinourteam.PopularLocation.p2.test"),
      name: i18n.t("pagejoinourteam.PopularLocation.p2.name"),
      locprof: i18n.t("pagejoinourteam.PopularLocation.p2.loc&prof"),
      photo: daniel,
    },
    {
      id: 3,
      x: 29.5,
      y: 80,
      test: i18n.t("pagejoinourteam.PopularLocation.p3.test"),
      name: i18n.t("pagejoinourteam.PopularLocation.p3.name"),
      locprof: i18n.t("pagejoinourteam.PopularLocation.p3.loc&prof"),
      photo: hector,
    },
    {
      id: 4,
      x: 19,
      y: 42,
      test: i18n.t("pagejoinourteam.PopularLocation.p4.test"),
      name: i18n.t("pagejoinourteam.PopularLocation.p4.name"),
      locprof: i18n.t("pagejoinourteam.PopularLocation.p4.loc&prof"),
      photo: santiago,
    },
  ];

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    // Obtener las dimensiones de la imagen del mapa cuando se haya cargado
    if (mapImageRef.current) {
      setMapWidth(mapImageRef.current.width);
      setMapHeight(mapImageRef.current.height);
    }
  }, []);

  const handlePointHover = (point) => {
    setSelectedPoint(point);
    setIsPopupVisible(true); // Mostrar el mensaje emergente cuando el cursor entra en el punto
  };

  // Nueva función para manejar el evento de salida del cursor del punto
  const handlePointLeave = () => {
    setIsPopupVisible(false); // Ocultar el mensaje emergente cuando el cursor sale del punto
  };

  return (
    <div className="mobile:w-full desktop:pl-4 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerCertification">
             
             {windowWidth >= 1025 ? 
              <div className="mt-24 mb-24">
              <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
              <p className='subTitleOurServices text-center'>{i18n.t("pagejoinourteam.PopularLocation.subtitle")}</p>
                    <p className="titleCompanies">{i18n.t("pagejoinourteam.PopularLocation.title")}</p>              
        
                  </div>

                  <div className='map-image-container'>
                  <img src={mapImage} alt="map" className=" mt-20 mapClass" ref={mapImageRef}/>
                    

                    {/* Renderizar los puntos en la imagen */}
                    {pointsData.map((point) => (
                        <MapPoint
                          key={point.id}
                          x={point.x}
                          y={point.y}
                          onPointHover={() => handlePointHover(point)}
                          onPointLeave={handlePointLeave}
                        />
                      ))}

                      {/* Mostrar el mensaje emergente si se ha seleccionado un punto */}
                      {isPopupVisible && selectedPoint && (
                        <div
                          className="map-popup"
                          style={{
                            left: `${selectedPoint.x + 15}%`,
                            top:`${selectedPoint.y - 0.12}%`,
                          }}
                        >
                          {/* Contenido del mensaje emergente */}
                          <p className='tx1_map'>"{selectedPoint.test}"</p>
                          <p className='tx2_map'>{selectedPoint.name}</p>
                          <p className='tx1_map'>{selectedPoint.locprof}</p>
                          {/* Renderizar la foto si es necesario */}
                          <img src={selectedPoint.photo} className='photo_map' alt="Foto" />
                        </div>
                      )}
                                   
                  </div>
                  
              </div>:
               <div className="mt-24 mb-24">
               <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
               <p className='subTitleOurServices text-justify'>{i18n.t("pagejoinourteam.PopularLocation.subtitle")}</p>
                     <p className="titleMap">{i18n.t("pagejoinourteam.PopularLocation.title")}</p>              
         
                   </div>

                   <div className='map-image-container'>
                  <img src={mapImage} alt="map" className="mapClass" ref={mapImageRef}/>

                      {/* Renderizar los puntos en la imagen */}
                      {pointsData.map((point) => (
                          <MapPoint
                            key={point.id}
                            x={point.x}
                            y={point.y}
                            onPointHover={() => handlePointHover(point)}
                            onPointLeave={handlePointLeave}
                          />
                        ))}

                        {/* Mostrar el mensaje emergente si se ha seleccionado un punto */}
                        {isPopupVisible && selectedPoint && (
                          <div
                            className="map-popup-mobile"
                            style={{
                              left: `${selectedPoint.x + 30}%`,
                              top:`${selectedPoint.y - 0.12}%`,
                            }}
                          >
                            {/* Contenido del mensaje emergente */}
                            <p className='tx1_map_mob'>"{selectedPoint.test}"</p>
                            <p className='tx2_map_mob'>{selectedPoint.name}</p>
                            <p className='tx1_map_mob'>{selectedPoint.locprof}</p>
                            {/* Renderizar la foto si es necesario */}
                            <img src={selectedPoint.photo} className='photo_map_mob' alt="Foto" />
                          </div>
                        )}
   
                  </div>

               </div>
              
            }

            
            </div>

  );
};

export default MapLocation;