import React, { useContext } from 'react';
import i18n from "i18next";
import Slider from "react-slick";
import { LoadingContext } from '../../components/context/LoadingContext';
import image1 from '../../images/career/imageOurCulture.png'

const OurCulture = ({}) => {

    const { windowWidth } = useContext(LoadingContext);


    

    return (
        <div className={`desktop:p-16 p-6 `}>
             {windowWidth >= 1025 ? 
             <div className="flex w-full">
                  
                    <div className="contentWhyUs divInfoExsisAcademy">
                    <div className="eventRightTimeLineMobile">
                        <p className='subTitleOurServices'>{i18n.t("pagejoinourteam.OurCulture.title")}</p>
                        <p className='titleOurServices'>{i18n.t("pagejoinourteam.OurCulture.subtitle")}</p> 
                    </div>
                       
                        <p className="textInfoExsisAcademy my-8">
                        {i18n.t("pagejoinourteam.OurCulture.description")}
                          </p>    
                          <p className="textInfoCulture">
                          {i18n.t("pagejoinourteam.OurCulture.text")}
                          </p>
                          
                          <div className='flex mt-8'>
                                <div>
                                    <p className='titleOurServices'> {i18n.t("pagejoinourteam.OurCulture.subtitle2")}</p>
                                    <p className='textInfo2Culture mt-4'> {i18n.t("pagejoinourteam.OurCulture.description2")}</p>
                                </div>
                                <div className='ml-12'>
                                    <p className='titleOurServices'> {i18n.t("pagejoinourteam.OurCulture.subtitle3")}</p>
                                    <p className='textInfo2Culture mt-4'> {i18n.t("pagejoinourteam.OurCulture.description3")}</p>
                                </div>
                          </div>
                        
                    </div> 

                    {windowWidth >= 1025 && windowWidth <= 1700 ?

                        <div className="  mt-10 divInfoExsisAcademyBootcamps flex ">    
                        <div className='ml-4 '>
                                <img src={image1} alt="...." className=""/>
                            </div>
                        </div>:
                         <div className="  mt-10  flex ">    
                         <div className='ml-4 '>
                                 <img src={image1} alt="...." className=""/>
                             </div>
                     </div>

                    }
                   
                    
                    </div>  :

                        <div className=" w-full">
                                        
                        <div className="contentWhyUs divInfoExsisAcademyMobile">
                        <div className="eventRightTimeLineMobile">
                            <p className='subTitleOurServices'>{i18n.t("pagejoinourteam.OurCulture.title")}</p>
                            <p className='titleOurServices'>{i18n.t("pagejoinourteam.OurCulture.subtitle")}</p> 
                        </div>
                            
                            <p className="textInfoCultureCarrers mt-8">
                            {i18n.t("pagejoinourteam.OurCulture.description")}
                            </p>  
                            <p className="textInfoExsisAcademy2Mobile mt-4">
                            {i18n.t("pagejoinourteam.OurCulture.text")}
                            </p> 
                            
                            <div className=''>
                                <div className='mt-8'>
                                    <p className='titleOurServices'> {i18n.t("pagejoinourteam.OurCulture.subtitle2")}</p>
                                    <p className='textInfoExsisAcademy2Mobile'> {i18n.t("pagejoinourteam.OurCulture.description2")}</p>
                                </div>
                                <div className='mt-8'>
                                    <p className='titleOurServices'> {i18n.t("pagejoinourteam.OurCulture.subtitle3")}</p>
                                    <p className='textInfoExsisAcademy2Mobile'> {i18n.t("pagejoinourteam.OurCulture.description3")}</p>
                                </div>
                          </div>
                           
                            
                        </div> 
                        <div className="  mt-10 divInfoExsisAcademyBootcampsMobile">
                        
                            
                        <div className='mt-4'>
                                <img src={image1} alt="...." className=""/>
                            </div>
                        </div>

                        </div> 
                                            
                                            
                                            }                
                                
          
        </div>
    )
}



export default OurCulture
