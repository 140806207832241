import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import arrowLeft from "./../../images/home/arrowLeft.jpg"
import arrowRigth from "./../../images/home/arrowRigth.jpg"
import { LoadingContext } from '../../components/context/LoadingContext';
import { makeStyles } from "@material-ui/core";
import i18n from "i18next";
import TargetVacancies from './TargetVacancies';
import { getOffersAllInfo } from './../../utils/api.js';
import { getOffersById } from './../../utils/api.js';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Pagination from '@material-ui/lab/Pagination';
import imgV1 from "../../images/targetVacancies/imgVacancie1.png"; //customer
import imgV2 from "../../images/targetVacancies/imgVacancie2.png"; //analyst - qa
import imgV3 from "../../images/targetVacancies/imgVacancie3.png"; //developer
import imgV4 from "../../images/targetVacancies/imgVacancie4.png"; //project manager - n.a
import imgV5 from "../../images/targetVacancies/imgVacancie5.png"; //architect - engineer

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  item: {
    padding: theme.spacing(1.2)
  },
  avatar: { marginRight: theme.spacing(5) },
  paginator: {
    justifyContent: "flex-end",
    padding: "10px",
    marginBottom: "24px",
    fontFamily: "AvenirMedium"
  }
}));

const Vacancies = ({  }) => {
  const { windowWidth } = useContext(LoadingContext);
  const classes = useStyles();
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const targetVacanciesRef = useRef(null);
  const jobsPerPage = 9;
  const [searchText, setSearchText] = useState('');
  const [filteredWorkArray, setFilteredWorkArray] = useState([]);
  const inputRef = useRef(null);
  const selectCountryRef = useRef(null);
  const selectTypeJobRef = useRef(null);
  const selectTimeRef = useRef(null);
  const [countryFilter, setCountryFilter] = useState('');
  const [jobTypeFilter, setJobTypeFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOkModalities, setOkModality] = useState(false);

  useEffect(() => {
    async function getJobs() {
      try {
        const response = await getOffersAllInfo(); //Endpoint with all vacancies and complete info
        const jobsWithInitialTime = response.data.obj.map((job) => ({
          ...job,
          ubication: `${job.cityName || ""}, ${job.countryName || ""}`,
          description: (job?.jobDescription.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '')).split(/\s+/).slice(0, 50).join(' ') + ' \u2026',
          timeCreated: formatDate(job.creationDateFormat, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          timeLast: formatDate(job.editedDateFormat, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          timeCreatedEN: formatDate(job.creationDateEnglishFormat, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          timeLastEN: formatDate(job.editedDateEnglishFormat, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          publication: job.creationDateFormat,
          vacancyImage: getVacancyImage(job.jobName),
          modality: job.locationModalityName,
          modalityEN: job.locationModalityNameEnglish,
          name: job.jobName

        }));
        setJobs(jobsWithInitialTime);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    getJobs();
    if (targetVacanciesRef.current) {
      targetVacanciesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ i18n.language]);
  
  // Función de filtrado de fechas
  const parsePublicationDate = (dateString) => {
    const months = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    const parts = dateString.split(' ');
    const day = parseInt(parts[0], 10);
    const monthText = parts[1];
    const month = months.findIndex((month) => month === monthText.toLowerCase());
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };

  const formatDate = (dateString, language) => {
    const months = {
      'es': ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    };
  
    const parts = dateString.split(' ');
    const day = parts[0];
    const monthText = parts[1].toLowerCase();
    const year = parts[2];
    const monthIndex = months['es'].indexOf(monthText);
  
    if (monthIndex !== -1) {
      const formattedMonth = months[language][monthIndex].substring(0, 3).charAt(0).toUpperCase() + months[language][monthIndex].substring(0, 3).slice(1);
      return `${day} ${formattedMonth} ${year}`;
    } else {
      // Si no se encuentra el mes, devolvemos la fecha original
      return dateString;
    }
  };

  const getVacancyImage = (jobName) => {
    const lowercaseJobName = jobName.toLowerCase(); // Convertir el nombre de la vacante a minúsculas para hacer coincidencias sin importar mayúsculas o minúsculas
  
    if (lowercaseJobName.includes("customer")) {
      return imgV1;
    } else if (lowercaseJobName.includes("analyst") || lowercaseJobName.includes("analista") || lowercaseJobName.includes("qa")) {
      return imgV2;
    } else if (lowercaseJobName.includes("developer") || lowercaseJobName.includes("dev") || lowercaseJobName.includes("desarrollador")) {
      return imgV3;
    } else if (lowercaseJobName.includes("project") || lowercaseJobName.includes("manager")) {
      return imgV4;
    } else if (
      lowercaseJobName.includes("architect") ||
      lowercaseJobName.includes("engineer") ||
      lowercaseJobName.includes("arquitecto") ||
      lowercaseJobName.includes("ingeniero")
    ) {
      return imgV5;
    } else {
      return imgV4;
    }
  };

  //Busqueda filtros
  const mapJobTypeToTime = (selectedJobType, language) => {
    const jobTypeMappings = {
        "1": "remoto",
        "2": "híbrido",
        "3": "presencial",
    };
    return jobTypeMappings[selectedJobType] || '';
  };

// Función de filtrado de fechas: Verificar si una fecha está dentro del rango de días especificado
const isWithinDaysRange = (date, days) => {
  const currentDate = new Date();
  const dateToCompare = new Date(date);
  currentDate.setHours(0, 0, 0, 0); // Establecer la hora a las 12:00 a.m. del día actual
  dateToCompare.setHours(0, 0, 0, 0); // Establecer la hora a las 12:00 a.m. de la fecha a comparar
  const differenceInTime = currentDate.getTime() - dateToCompare.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays <= days;
};

  const handleSearch = () => {
    const filteredJobs = jobs.filter((job) => {
      const jobNameLowercase = job.name.toLowerCase();
      const searchTextLowercase = searchText.toLowerCase();
      const countryFilterLowercase = countryFilter.toLowerCase();
      const jobTypeFilterLowercase = jobTypeFilter.toLowerCase();
      // Realiza las comparaciones con los valores de filtro
      const nameMatches = jobNameLowercase.includes(searchTextLowercase);
      const countryMatches = job.ubication.toLowerCase().includes(countryFilterLowercase);
      // Obtiene el valor de tiempo adecuado para la comparación según el idioma
      const timeValue = mapJobTypeToTime(jobTypeFilter, i18n.language);
      // Compara time con el valor obtenido del mapeo, todo en minúsculas
      const jobTypeMatches = job.modality.toLowerCase().includes(timeValue);
    // Filtra las ofertas de trabajo según la opción de dateRange seleccionada
    let publicationDateMatches = true; // Inicialmente, no aplicar filtro de fecha
    if (dateRangeFilter) {
      const publicationDate = parsePublicationDate(job.publication);
      switch (dateRangeFilter) {
        case '1': // Opción "1": Trabajos publicados entre la fecha actual y un día antes
          publicationDateMatches = isWithinDaysRange(publicationDate, 1);
          break;
        case '2': // Opción "2": Trabajos publicados en la última semana (7 días)
          publicationDateMatches = isWithinDaysRange(publicationDate, 7);
          break;
        case '3': // Opción "3": Trabajos publicados en las últimas dos semanas (14 días)
          publicationDateMatches = isWithinDaysRange(publicationDate, 14);
          break;
        case '4': // Opción "4": Trabajos publicados en las últimas tres semanas (21 días)
          publicationDateMatches = isWithinDaysRange(publicationDate, 21);
          break;
        case '5': // Opción "5": Trabajos publicados hace más de 1 mes (más de 30 días)
          publicationDateMatches = !isWithinDaysRange(publicationDate, 30);
          break;
        default:
          break;
      }
    }
      return nameMatches && countryMatches && jobTypeMatches && publicationDateMatches;
    });
    setFilteredWorkArray(filteredJobs);
    setHasAppliedFilters(true);
  };  

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = useCallback(() => {
    setSearchText('');
    setFilteredWorkArray([]);
    setCountryFilter('');
    setJobTypeFilter('');
    setDateRangeFilter('');
    inputRef.current.value = '';
    setHasAppliedFilters(false);
  }, [setFilteredWorkArray]);

  const handlePageChange = useCallback((event, newPage) => {
    setPage(newPage);
    if (targetVacanciesRef.current) {
      targetVacanciesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [setPage]);

  const paginatedJobs = jobs.slice((page - 1) * jobsPerPage, page * jobsPerPage);
  const filteredWorkArray2 = filteredWorkArray.slice((page - 1) * jobsPerPage, page * jobsPerPage);

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 " ref={targetVacanciesRef}>
              <div className={windowWidth >= 1700 ? "flip-card-inner2 desktop:ml-16 mt-24 marginRVacanciesResponsive" : windowWidth > 1025 ? "flip-card-inner2 desktop:ml-16 mt-24 marginRVacancies" : "flip-card-inner2 desktop:ml-16 mt-24"}>
              <div className='mobile:ml-4'>
                    <p className='subTitleOurServices'>{i18n.t("pagejoinourteam.vacancies.title")}</p>
                    <p className='titleOurServices'>{i18n.t("pagejoinourteam.vacancies.subtitle")}</p> 
                    </div>
                    <div className=''>
                      <div>
                        {windowWidth >= 1025 ? 
                          <p className="textCoreSolutionsMobile desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
                          {i18n.t("pagejoinourteam.vacancies.description")}
                          </p>:
                          <p className="textCoreSolutionsMobile desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
                          {i18n.t("pagejoinourteam.vacancies.description")}
                          </p>
                        }
                      

                        </div>
                       { windowWidth >= 1025 ? 
                       
                        <div className='flex'>
                            <div>
                            <input ref={inputRef} onChange={handleSearchInputChange} className={windowWidth > 1700 ? "inputVacanciesResponsive":"inputVacancies"}  type="text" placeholder={i18n.t("pagejoinourteam.vacancies.search.txtSearch")}/>
                            </div>

                            <div className={windowWidth > 1700 ? "inputVacancies2Responsive":"inputVacancies2"}>
                              <CountryDropdown
                                value={countryFilter}
                                className="w-full"
                                onChange={(val) => setCountryFilter(val)}
                                defaultOptionLabel={i18n.t("pagejoinourteam.vacancies.search.location")} 
                                ref={selectCountryRef}
                              />
                            </div>
                            <div className={windowWidth > 1700 ? "inputJobTypeResponsive":'inputJobType'}>
                              <select
                                name="jobtype"
                                className="w-full"
                                value={jobTypeFilter}
                                onChange={(event) => setJobTypeFilter(event.target.value)}
                                ref={selectTypeJobRef}>
                                    <option value="" hidden selected>
                                      {i18n.t("pagejoinourteam.vacancies.search.jobSelect")}
                                    </option>
                                    <option className="optionSelect" disabled value="">{i18n.t("pagejoinourteam.vacancies.search.jobtype")}</option>
                                    <option value="1">{i18n.t("pagejoinourteam.vacancies.search.jobtypeOptions.remote")}</option>
                                    <option value="2">{i18n.t("pagejoinourteam.vacancies.search.jobtypeOptions.hybrid")}</option>
                                    <option value="3">{i18n.t("pagejoinourteam.vacancies.search.jobtypeOptions.inperson")}</option>
                              </select>
                            </div>
                      
                            <div className={windowWidth > 1700 ? "inputDateRangeResponsive":'inputDateRange'}>
                                <select
                                  name="daterange"
                                  className="w-full"
                                  value={dateRangeFilter}
                                  onChange={(event) => setDateRangeFilter(event.target.value)}
                                  ref={selectTimeRef}>
                                      <option value="" hidden selected>
                                        {i18n.t("pagejoinourteam.vacancies.search.dateSelect")}
                                      </option>

                                      <option className="optionSelect" disabled value="">{i18n.t("pagejoinourteam.vacancies.search.daterange")}</option>
                                      <option value="1">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op1")}</option>
                                      <option value="2">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op2")}</option>
                                      <option value="3">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op3")}</option>
                                      <option value="4">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op4")}</option>
                                      <option value="5">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op5")}</option>

                                </select>
                            </div>

                            <button className={windowWidth >= 1700 ? 'buttonSearchWorkResponsive' : 'buttonSearchWork '} style={{alignSelf:'center'}} onClick={handleSearch}>
                              <p className='textButtomSearchWork'>{i18n.t("pagejoinourteam.vacancies.search.search")}</p>
                            </button>

                            <button 
                              className={ windowWidth >= 1700 ? `buttonClearSearchResponsive ${!hasAppliedFilters ? "buttonClearSearchDisabled" : ""}` : `buttonClearSearch ${!hasAppliedFilters ? "buttonClearSearchDisabled" : ""}`}
                              style={{alignSelf:'center'}} onClick={handleClearSearch}>
                              <p className='textButtomSearchWork'>{i18n.t("pagejoinourteam.vacancies.search.clear")}</p>
                            </button>
                        </div>
                        
                     :
                        <div className=''>
                            <div>
                              <input ref={inputRef} onChange={handleSearchInputChange} className="inputVacanciesMobile2"  type="text" placeholder={i18n.t("pagejoinourteam.vacancies.search.txtSearch")}/>
                            </div>

                            <div className="inputVacanciesMobile">
                            <CountryDropdown
                                    value={countryFilter}
                                    className="w-full"
                                    onChange={(val) => setCountryFilter(val)}
                                    defaultOptionLabel={i18n.t("pagejoinourteam.vacancies.search.location")} 
                                    ref={selectCountryRef}
                                  />
                            </div>
                            <div className='inputJobTypeMobile'>
                              <select
                                  name="jobtype"
                                  className="w-full"
                                  value={jobTypeFilter}
                                  onChange={(event) => setJobTypeFilter(event.target.value)}
                                  ref={selectTypeJobRef}>
                                      <option value="" hidden selected>
                                        {i18n.t("pagejoinourteam.vacancies.search.jobSelect")}
                                      </option>
                                      <option className="optionSelect" disabled value="">{i18n.t("pagejoinourteam.vacancies.search.jobtype")}</option>
                                      <option value="1">{i18n.t("pagejoinourteam.vacancies.search.jobtypeOptions.remote")}</option>
                                      <option value="2">{i18n.t("pagejoinourteam.vacancies.search.jobtypeOptions.hybrid")}</option>
                                      <option value="3">{i18n.t("pagejoinourteam.vacancies.search.jobtypeOptions.inperson")}</option>
                              </select>
                            </div>
                  
                            <div className='inputDateRangeMobile'>
                              <select
                                  name="daterange"
                                  className="w-full"
                                  value={dateRangeFilter}
                                  onChange={(event) => setDateRangeFilter(event.target.value)}
                                  ref={selectTimeRef}>
                                      <option value="" hidden selected>
                                        {i18n.t("pagejoinourteam.vacancies.search.dateSelect")}
                                      </option>
                                      <option className="optionSelect" dissable value="">{i18n.t("pagejoinourteam.vacancies.search.daterange")}</option>
                                      <option value="1">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op1")}</option>
                                      <option value="2">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op2")}</option>
                                      <option value="3">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op3")}</option>
                                      <option value="4">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op4")}</option>
                                      <option value="5">{i18n.t("pagejoinourteam.vacancies.search.daterangeOptions.op5")}</option>

                              </select>
                            </div>

                          <button className='buttonSearchWorkMobile ' style={{alignSelf:'center'}} onClick={handleSearch}>
                              <p className='textButtomSearchWork'>{i18n.t("pagejoinourteam.vacancies.search.search")}</p>
                          </button>

                          <button 
                            className={`buttonClearSearchMobile ${!hasAppliedFilters ? "buttonClearSearchDisabled" : ""}`}
                            style={{alignSelf:'center'}} onClick={handleClearSearch}>
                              <p className='textButtomSearchWork'>{i18n.t("pagejoinourteam.vacancies.search.clear")}</p>
                            </button>
                        </div>}

                        </div>

                        { windowWidth >= 1025 ?
                          <>
                              {isLoading ? (
                                <div className=''>
                                  <p  className='resultsSearch mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 '>
                                    {i18n.t("pagejoinourteam.vacancies.loading")}</p>
                                </div>    
                              ) : (
                                <>
                                {filteredWorkArray.length === 0 && hasAppliedFilters ? (
                                <div className=''> 
                                    <p  className='resultsSearch mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4'>
                                      {i18n.t("pagejoinourteam.vacancies.notDataFound")}</p>
                                      </div>
                                ) : (
                                  <>
                                    <TargetVacancies workArray={filteredWorkArray2.length > 0 ? filteredWorkArray2 : paginatedJobs} />

                                    { hasAppliedFilters ? (
                                    <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '16px', width: '90%' }}>
                                      <Pagination
                                        count={Math.ceil(filteredWorkArray.length / jobsPerPage)}
                                        page={page}
                                        onChange={handlePageChange}
                                        defaultPage={1}
                                        size="medium"
                                        classes={{ ul: classes.paginator }}
                                        />
                                        </div>
                                    ):(
                                    <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '16px', width: '90%' }}>
                                        <Pagination
                                          count={Math.ceil(jobs.length / jobsPerPage)}
                                          page={page}
                                          onChange={handlePageChange}
                                          defaultPage={1}
                                          size="medium"
                                          classes={{ ul: classes.paginator }}
                                          />
                                      </div>
                                    )}
                                      </>
                                    )}
                                  </>
                                )}
                          </>

                        :
                          <>
                              {isLoading ? (
                                <div className=''>
                                  <p  className='resultsSearchMob mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 '>
                                    {i18n.t("pagejoinourteam.vacancies.loading")}</p>
                                </div>    
                              ) : (
                                <>
                                {filteredWorkArray.length === 0 && hasAppliedFilters ? (
                                <div className=''> 
                                    <p  className='resultsSearchMob mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4'>
                                      {i18n.t("pagejoinourteam.vacancies.notDataFound")}</p>
                                      </div>
                                ) : (
                                  <>
                                    <TargetVacancies workArray={filteredWorkArray2.length > 0 ? filteredWorkArray2 : paginatedJobs} />
                                    { hasAppliedFilters ? (
                                    <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '16px', width: '90%' }}>
                                      <Pagination
                                        count={Math.ceil(filteredWorkArray.length / jobsPerPage)}
                                        page={page}
                                        onChange={handlePageChange}
                                        defaultPage={1}
                                        size="medium"
                                        classes={{ ul: classes.paginator }}
                                        />
                                        </div>
                                    ):(
                                    <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '16px', width: '90%' }}>
                                        <Pagination
                                          count={Math.ceil(jobs.length / jobsPerPage)}
                                          page={page}
                                          onChange={handlePageChange}
                                          defaultPage={1}
                                          size="medium"
                                          classes={{ ul: classes.paginator }}
                                          />
                                      </div>
                                    )}
                                      </>
                                    )}
                                  </>
                                )}
                                </>
                              }
                              </div>
                            </div>
                    );
                  };

export default Vacancies;